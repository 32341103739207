<template>
    <div class="card-align">
        <div class="card">
            <div class="card-body">
                <div class=" pointer algin-right">
                    <span @click="editViewDetails()" class="icon-box-new-threat">
                        <feather-icon class=" rotate" icon="EditIcon" size="20" />
                    </span>
                    <span @click="updateDetailsView()" v-if="editableValue">
                        <button class="btn pointer btn-primary btn-sm margin-right" :disabled="!this.$store.state.auth.permissions.includes('threat-update')">update</button>
                    </span>
                </div>
                <div class="meetup-header d-flex align-items-center">
                    <div class="my-auto" style="width:100%;">
                        <label v-if="editableValue">Name</label>
                        <div id="threatname" :class="[{'bordereditable': editableValue}, 'font-Weight-700']"
                            :contenteditable="editableValue">
                            {{currentData.name}}</div>
                        <div v-if="editableValue" class="mr-bottm">
                            <label>Threat Value</label>
                            <v-select v-model="selectedData" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title" :options="option" />
                        </div>
                        <label v-if="editableValue">Description</label>
                        <div id="threatdescription" :class="[{'textarea-editable': editableValue}]"
                            :contenteditable="editableValue">{{currentData.description}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { BFormTextarea, BSidebar, VBToggle, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { methods } from 'vue-echarts';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
    threatDetailID:'',
    currentData: [],
    editableValue: false,
    selectedData: [],
    dir: 'ltr',
        option: [
            { title:  'Very Low', value: 1  },
            { title: 'Low', value: 2  },
            { title:  'Medium', value: 3  },
            { title: 'High', value: 4  },
            { title: 'Very High', value: 5 }
        ],
  }),
components: {
vSelect
},
props: {
   
},
mounted(){
    this.threatDetailID = this.$route.params.id
    this.getThreat(this.threatDetailID)
},
methods: {
    getThreat(id) {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/threats/${id}`,
        };
        this.$http(options)
            .then((res) => {
                this.currentData = res.data.data
                if(this.currentData.threat_value){
                    this.option.forEach(ele=> {
                        if(ele.value == this.currentData.threat_value) {
                            this.selectedData.push(ele)
                        }
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },
        editViewDetails() {
        this.editableValue = true
    },
        updateDetailsView() {
        var name = document.getElementById("threatname").innerText;
        var decription = document.getElementById("threatdescription").innerText;
        this.assetName = name
        this.assetsDescription = decription
          let data = {
            name: this.assetName,
            description: this.assetsDescription,
            threat_value: this.selectedData.value
        };
        const options = {
            method: "PUT",
            data: data,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/threats/${this.threatDetailID}`,
        };
        this.$http(options)
            .then((res) => {
                // this.getAssets()
                this.assetName = ''
                this.assetsDescription =''
              this.editableValue = false
              if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Threat Updated !",
                icon: "EditIcon",
                variant: "success",
              },
            });
            
          }
            })
            .catch((err) => {
                console.log(err);
            });
    },
}

}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.flex-inline{
    display: inline-flex;
    margin-right: 5px;
}
.mr-bottm {
    margin-bottom: 10px;
}
.mr-right {
    margin-right: 5px;
}
.card-design {
    display: flex;
    justify-content: space-between;
}
.mr-50{
    margin-right: 100px !important;
}
.flex {
    display: flex;
}
.padding-card {
    padding-bottom: 1.5rem;
}
.bordereditable {
    border: 1px solid #d1cdcd;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    outline: none;
    width: 100%;
    // height: 35px;
}
.algin-right {
    display: flex;
    align-items: center;
    justify-content: end;
}
.textarea-editable {
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    border: 1px solid #d1cdcd;
    font: medium -moz-fixed;
    font: -webkit-small-control;
    height: 65px;
    overflow: auto;
    padding: 2px;
    resize: both;
    width: 100%;
    border-radius: 5px;
    outline: none;
}
.font-Weight-700 {
    font-weight: 700;
}
.icon-box-new-threat {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
} 
.text-primary-black {
  color: #ffff;
}
.meetup-header {
    margin-bottom: 2rem;
}
.meetup-day {
    border-right: 1px solid #d8d6de;
    padding-right: 1.3rem;
    margin-right: 1.3rem;
    text-align: center;
}

</style>
